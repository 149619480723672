<!-- 设置 -->
<template>
  <div id="Setup">
    <!-- 顶部导航 -->
    <van-nav-bar :title="$t('m_mime.sz')" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <!-- 内容 -->
    <ul class="wrap">
      <li class="" @click="jumpCpjs">
        <span class="left"> {{ $t("m_settings.cpjs") }} </span><i class="iconfont icon-arrow-right"></i>
      </li>
      <li class="" @click="jumpLang">
        <span class="left"> {{ $t("m_settings.yysz") }} </span><i class="iconfont icon-arrow-right"></i>
      </li>
    </ul>
    <!-- 按钮 -->
    <div class="submit">
      <span class="submit-btn" @click="change">{{
          $t("m_settings.qhzh")
      }}</span>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { mapActions } from "vuex";
export default {
  name: "",
  data() {
    return {
      timer: null,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() { },
  destroyed() {
    console.log("销毁了");
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions(["LogOut"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      Toast("按钮");
    },
    jumpCpjs() {
      this.$router.push("/help");
    },
    jumpLang() {
      this.$router.push("/langsetting");
    },
    change() {
      Dialog.confirm({
        title: this.$t("common.msg"),
        message: this.$t("header.exitMsg"),
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
      }).then(() => {
        this.LogOut();
        this.timer = setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar {
  /* background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important; */
  background-color: rgb(50, 59, 92) !important;
}

/deep/.van-nav-bar__title {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-nav-bar .van-icon {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

#Setup {
  height: 100%;
  background-color: #303e70;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#Setup .wrap {
  width: 100%;
  padding: 0.15rem;
  box-sizing: border-box;

  li {
    width: 100%;
    height: 1rem;
    background-color: #2b2e43;
    border-radius: 0.2rem;
    padding: 0.15rem;
    margin-bottom: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    box-sizing: border-box;
  }
}

#Setup .submit {
  width: 100%;
  margin-top: 0.523rem;
  position: relative;
  flex: 1;

  .submit-btn {
    position: absolute;
    bottom: 1.1rem;
    left: 1.74rem;
    right: 1.74rem;
    height: 0.9rem;
    line-height: 0.88rem;
    margin: 0 auto;
    display: block;
    background-image: linear-gradient(90deg, #2948ff, #396afc);
    border-radius: 0.45rem;
    color: #ffffff;
    text-align: center;
    font-size: 0.34rem;
    font-weight: normal;
    font-stretch: normal;
  }
}

/deep/.van-dialog {
  background-color: #fff !important;
}
</style>